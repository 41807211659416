import Vue from "vue";
import Router from "vue-router";

// Views
const Images = () => import("@/views/Images");
const Login = () => import("@/views/Login");

Vue.use(Router);

export default new Router({
    mode: "hash", // https://router.vuejs.org/api/#mode
    linkActiveClass: "active",
    scrollBehavior: function () {return { y: 0 };},
    routes: configRoutes(),
    base: process.env.BASE_URL,
});

function configRoutes() {
    return [
        {
            path: "/",
            redirect: "/images",
        },
        {
            path: "/images/:productid?",
            name: "images",
            component: Images,
        },
        {
            path: "/images-kollektion/:collid?",
            name: "images-kollektion",
            component: Images,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
    ];
}
