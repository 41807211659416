import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import router from "./router"
import vueRescource from "vue-resource"

import "spectre.css/dist/spectre.min.css";
import "spectre.css/dist/spectre-icons.min.css";
import "./assets/main.css";

Vue.use(Vuex);
Vue.use(vueRescource);

Vue.config.productionTip = false;

Vue.config.api_url = "http://scm:88/local.productfiles/www/server";

if (location.hostname.match("trend-stoff.4you.cc")) {
    Vue.config.api_url = "https://trend-stoff.4you.cc/server";
}

/* eslint-disable no-new */
new Vue({
    el: "#app",
    components: {
        App
    },
    // Attach the Vue instance to the window, so it's available globally.
    created: function () {
        window.Vue = this;
    },
    Vuex,
    router,
    render: function (h) {return h(App)},
});
